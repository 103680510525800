/*
TODO:
Not bound properly.
Previously Claimed For Illness:
Where Did Accident Injury Occur:
How Did Accident Injury Occur:

Added Async and Await to all updates as the application was crashing when no Maximum_Cover on the cost and invoice page
*/
import { SignatureMissing } from "../components/UI/Logo/LogoMedoSync";
import React, { useState } from "react";
import EdiText from "react-editext";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import Radio from "@material-ui/core/Radio";
// import { handleInputChange } from "../redux/actions";
import Button from "@material-ui/core/Button";
import CodeInput from "../components/UI/CodeInput/CodeInput";
import { remove_linebreaks2 } from "../lib/removeLineBreaks";
import { useLocation, useParams } from "react-router";
import {
  usePatientbyidQuery,
  useGetInvoiceForPatientQuery,
  PatientbyidQuery,
  useInsurancePolicyQuery,
  useCreateInvoiceMutation,
  useUpdatePatientMutation,
  UpdatePatientMutationVariables,
  useInvoiceBillQuery,
  useVerifyIrishMemberQuery,
  useVerifyLayaMemberQuery,
  useGetMemberQuery,
} from "../generated/graphql";
// import Input from '../components/UI/Input/Input';
import {
  convertUnixDate4,
  formatDate,
  convertUnixDate,
  convertUnixDate2 /*, dateConverter*/,
  convertUnixDate3,
} from "../lib/dateConverter";
// import { useOnClickVerifyLayaMember } from "../lib/useOnClickVerifyLayaMember"

const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#42b0f5",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "5px 5px",
    borderColor: "#42d7f5",
    borderStyle: "solid",
    marginBottom: "20px",
  },
}));

export default function AdminRegistrationPractice({ history }) {
  const location = useLocation();
  const isEditPatient = location.pathname.startsWith("/edit-patient");
  console.log("isEditPatient1 ", isEditPatient);
  // const isResubmit = location.pathname.startsWith("/resubmit-patient");
  const isResubmitPatient = location.pathname.startsWith("/resubmit-patient");
  const isResubmitInsurance = location.pathname.startsWith(
    "/resubmit-insurance"
  );
  const { mmpid } = useParams<{ mmpid: string }>();
  const pid = +mmpid; // fast way to convert to a number using +mmpid the + makes it a number
  // const username = JSON.parse(localStorage.getItem("user") as string).firstName;
  // console.log("username ", username);

  useGetInvoiceForPatientQuery({
    onCompleted: ({ getInvoiceForPatient }) => {
      if (getInvoiceForPatient) {
        history.replace(`/cost-and-invoice/${pid}`);
      }
    },
    skip: !pid || isEditPatient || isResubmitInsurance || isResubmitPatient,
    variables: {
      patientId: pid,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const [numberOfPreviousVisits, setNumberOfPreviousVisits] = useState(""); //Added for Migration
  const [ILH_Policy_Number, setILH_Policy_Number] = useState(""); //Added for Migration
  // const membershipNumberRegex = /^4\d{9,}|6\d{9,}|0{3}\d{7,}$/; //Added for Migration -- validation on the numbers input for ILH & Laya
  //TODO: regex ADD to library function instead of here!
  const membershipNumberRegex7Zeros = /^0{7}/; //Catch all zeros
  const membershipNumberRegexILH = /^\d{6,7}$/;
  const membershipNumberRegexLaya = /^4\d{9,}|6\d{9,}$/;
  // const policyNumberRegex = /^0{0,3}VIV[CGW]?\d{3,}/; //Added for Migration
  //   const numberOfPreviousVisitsRegex = /^[0-9]{1}/; //; //Added for Migration
  console.log("pid ", pid);
  console.log("mmpid ", mmpid);
  const [memberNo, setMemberShipNo] = useState("");
  const [maxCover, setMaxCover] = useState(0);
  const [patient, setPatient] =
    useState<PatientbyidQuery["patientbyid"]>(undefined);
  const [mutate] = useCreateInvoiceMutation();
  const [updatePatientMutation] = useUpdatePatientMutation();

  const { data: insuranceData } = useInsurancePolicyQuery();
  const insuPolicies = insuranceData?.InsurancePolicy || [];

  //insuPolicies reads from all plans from the hospital_polices table and invoicePolicy reads the plan stamped to the invoice table
  const { data: invoiceBillData } = useInvoiceBillQuery({
    variables: { patientId: pid },
  });
  // const [insurerIdAndPlanName, setInsurerIdAndPlanName] = useState("");
  // const [insuranceILHPolicyNumber, setILHInsurancePolicyNumber] = useState("");
  // const [insuranceILHMemberNumber, setILHInsuranceMemberNumber] = useState("");
  // const [insuranceLayaPolicyNumber, setLayaInsurancePolicyNumber] = useState("");

  const [localDBPolicyNumber, setLocalDBPolicyNumber] = useState("");
  const [localDBMemberNumber, setLocalDBMemberNumber] = useState("");
  // const [localDBFirstName, setLocalDBFirstName] = useState("");
  // const [localDBSurname, setLocalDBSurname] = useState("");
  const [insurerIdAndPlanName, setInsurerIdAndPlanName] = useState("");
  const [localDBStatusMessage, setLocalDBStatusMessage] = useState("");
  const [layaInsurancePlanType, setLayaInsurancePlanType] = useState("");
  const [layaInsurancePlanName, setLayaInsurancePlanName] = useState("");
  // const [ts_InsurerAPIQueried, setTs_InsurerAPIQueried] = useState("");
  const [insertedIntoLocalDb, setInsertedIntoLocalDb] = useState(false);
  let isExcessModel = "";

  

  //ILH Error Code
  //"code": 404,
  //Laya error code
  // statusCode": 4000,
  // "statusMessage": "Member not found",

  const { data: patientData } = usePatientbyidQuery({
    onCompleted: (data) => {
      if (data.patientbyid) {
        setPatient(data.patientbyid);
        if (data.patientbyid?.treatmentsAndDoctorsForInvoice.length) {
          const insurancePlanAndCompanyName = `${data.patientbyid?.Health_Insurance_Company}|${data.patientbyid?.treatmentsAndDoctorsForInvoice[0]?.invoice?.Insurance_Plan_Name}`;
          if (insurancePlanAndCompanyName) {
            setInsurerIdAndPlanName(insurancePlanAndCompanyName);
          }
          const insuPolicyName =
            data.patientbyid?.treatmentsAndDoctorsForInvoice[0]?.invoice
              ?.Insurance_Plan_Name;
          const insurerId = data.patientbyid?.Health_Insurance_Company;
          let coverCost = insuPolicies.find(
            (v) =>
              v.Policy_Plan?.toLowerCase() === insuPolicyName &&
              v.InsurerId === insurerId &&
              v.IsPolicyActive === true //this is for the health insurance policy status in the DB of 0 || 1 -- this is used for updating the policy level
          )?.MaxCover;
          if (coverCost || coverCost === 0) {
            setMaxCover(coverCost);
          }
        }
        if (data.patientbyid?.ILH_Policy_Number) {
          setILH_Policy_Number(data.patientbyid?.ILH_Policy_Number);
        }
        if (data.patientbyid?.Health_Membership_Number) {
          setMemberShipNo(data.patientbyid?.Health_Membership_Number);
        }
        if (
          data.patientbyid?.treatmentsAndDoctorsForInvoice.length &&
          data.patientbyid?.treatmentsAndDoctorsForInvoice[0]?.invoice
            ?.Policy_IsActive_YesNo // this is the yes or no option box when the patients insurance is verified in the portal.
        ) {
          setPolicyActive(
            data.patientbyid?.treatmentsAndDoctorsForInvoice[0]?.invoice
              ?.Policy_IsActive_YesNo
          );
        }
      }
    },
    skip: !pid,
    variables: { id: pid },
    context: { passAuthHeader: true },
  });

  const isLayaPatient =
    patientData?.patientbyid?.Health_Insurance_Company === 1 ||
    patientData?.patientbyid?.Health_Insurance_Company === 5;
  //Laya API Get Member
  const { data: verifyLayaMemberData } = useVerifyLayaMemberQuery({
    variables: {
      id: patient?.id ? parseInt(patient.id) : 0,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip: !patient || !isLayaPatient,
    onCompleted: ({ verifyLayaMember }) => {
      console.log("verifyLayaMember: ", verifyLayaMember);
      // const memNo = verifyLayaMember?.memNo;
      // setLayaInsurancePolicyNumber(memNo || "");
      // console.log("memNo ", memNo);
      if (verifyLayaMember) {
        const memNo = verifyLayaMember?.memNo;
        if (memNo) {
          setLocalDBPolicyNumber(memNo || "");
          // setLayaInsurancePolicyNumber(memNo || "");
          console.log("memNo ", memNo);
        }
        const planName = verifyLayaMember?.planName;
        if (planName) {
          setInsurerIdAndPlanName(planName || "");
          setLayaInsurancePlanName(planName || "");
          console.log("planName ", planName);
        }
        if (verifyLayaMember.firstName) {
          setFirstName(verifyLayaMember.firstName);
        }
        if (verifyLayaMember.surname) {
          setLastName(verifyLayaMember.surname);
        }
        // setAddress(verifyLayaMember.address)
        // setDOB(verifyLayaMember.insurer_DOB)
        if (verifyLayaMember.statusMessage) {
          setLocalDBStatusMessage(verifyLayaMember.statusMessage);
        }
        setInsertedIntoLocalDb(true);
      }
    },
  });

  const memberLayaPolicy = verifyLayaMemberData?.verifyLayaMember?.[0];
  console.log("memberLayaPolicy ", memberLayaPolicy);

  const isILHPatient = patientData?.patientbyid?.Health_Insurance_Company === 2;

  // const DoNotUseExcessCalculation =
  //   (patientData?.patientbyid?.Health_Insurance_Company === 3 ||
  //     patientData?.patientbyid?.Health_Insurance_Company === 4 ||
  //     patientData?.patientbyid?.Health_Insurance_Company === 6) === true
  //     ? true
  //     : false;
  // console.log("DoNotUseExcessCalculation ", DoNotUseExcessCalculation);

  //ILH API Get Member
  const { data: verifyIrishMemberData } = useVerifyIrishMemberQuery({
    variables: {
      id: patient?.id ? parseInt(patient.id) : 0,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip: !patient || !isILHPatient,
    onCompleted: ({ verifyIrishMember }) => {
      console.log("verifyIrishMember: ", verifyIrishMember);
      if (verifyIrishMember?.member_number.length) {
        if (verifyIrishMember.plan) {
          setInsurerIdAndPlanName(verifyIrishMember.plan || "");
        }
        if (verifyIrishMember.policy_number) {
          setLocalDBPolicyNumber(verifyIrishMember.policy_number);
          // setILHInsurancePolicyNumber(policy_number);
        }
        if (verifyIrishMember.member_number) {
          setLocalDBMemberNumber(verifyIrishMember.member_number);
        }
        if (verifyIrishMember.firstName) {
          setFirstName(verifyIrishMember.firstName);
        }
        if (verifyIrishMember.lastname) {
          setLastName(verifyIrishMember.lastname);
        }
        if (verifyIrishMember.address) {
          setAddress(verifyIrishMember.address);
        }
        if (verifyIrishMember.insurer_DOB) {
          setDOB(verifyIrishMember.insurer_DOB);
        }
        if (verifyIrishMember.policy_status) {
          setLocalDBStatusMessage(verifyIrishMember.policy_status);
        }
        setInsertedIntoLocalDb(true);
      }
    },
  });
  const memberILHPolicy = verifyIrishMemberData?.verifyIrishMember?.[0];
  console.log("memberILHPolicy ", memberILHPolicy);

  //Local DB Get Member
  const { data: getMemberData } = useGetMemberQuery({
    variables: {
      firstName: patient?.Patient_Forenames as string, //"Seamus",
      surname: patient?.Patient_Surname as string, //"Cooley",
      DOB: patient?.DOB as string, //"1976-09-09",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip:
      !patient ||
      !insertedIntoLocalDb ||
      !patient?.Patient_Forenames ||
      !patient?.Patient_Surname ||
      !patient?.DOB,
    onCompleted: ({ getMember }) => {
      console.log("getMember: ", getMember);
      if (getMember?.length) {
        const {
          policy_number,
          member_number,
          planName,
          statusMessage,
          planType,
        } = getMember[0];
        if (policy_number) {
          setLocalDBPolicyNumber(policy_number);
        }
        if (member_number) {
          setLocalDBMemberNumber(member_number);
        }
        if (planName) {
          setInsurerIdAndPlanName(planName);
        }
        if (statusMessage) {
          setLocalDBStatusMessage(statusMessage);
        }
        if (planType) {
          setLayaInsurancePlanType(planType);
        }
        console.log("planType ", planType);
      }
    },
  });
  console.log("layaInsurancePlanType ", layaInsurancePlanType);
  const memberPolicyFromDB = getMemberData?.getMember?.[0];
  console.log("memberPolicyFromDB ", memberPolicyFromDB);
  console.log("getMemberData?.getMember[0].planType ", getMemberData?.getMember[0].planType);
  isExcessModel = getMemberData?.getMember[0].planType === "excess" ? "Yes" : "No";
  
  const UseExcessCalculation =
    (patientData?.patientbyid?.Health_Insurance_Company === 3 ||
      patientData?.patientbyid?.Health_Insurance_Company === 4 ||
      patientData?.patientbyid?.Health_Insurance_Company === 6) === true
      ? "No"
      : isExcessModel;
  console.log("UseExcessCalculation ", UseExcessCalculation);

  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const [policyActive, setPolicyActive] = useState("");
  const [dob, setDOB] = useState("");
  const [dateInsurerInvoiced, setDateInsurerInvoiced] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [adderss, setAddress] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [gpfirstname, setGPFirstName] = useState("");
  const [gplastname, setGPLastName] = useState("");
  const [gpconsent, setGPConsent] = useState("");
  const [gppractice, setGPPractice] = useState("");
  const [nextofkin, setNextOfKin] = useState("");
  const [nextofkinnumber, setNextOFKinNumber] = useState("");
  const [electprivate, setElectPrivate] = useState("");
  const [symptomsfirstnoticed, setSimptomsFirstNoticed] = useState("");
  const [drfirstconsulted, setDrFirstConsulted] = useState("");
  const [dateclaimedbefore, setDateClaimedBefore] = useState("");
  const [drnamefirstreferred, setDrNameFirstReferred] = useState("");
  const [drnamefirstreferreddate, setDrNameFirstReferredDate] = useState("");
  const [drnamefirstreferredaddrtess, setDrNameFirstReferredAddress] =
    useState("");
  const [resultofaccident, setResultOfAccident] = useState("");
  const [dateofaccident, setDateOfAccident] = useState("");
  const [faultofotherparty, setFaultOfOtherParty] = useState("");
  const [contactoinformationotherparty, setContactInformationOtherParty] =
    useState("");
  const [
    responsiblepartyinsurancecompany,
    setResponsiblePartyInsuranceCompany,
  ] = useState("");
  const [claimingviasolicitor, setClaimingViaSolicitor] = useState("");
  const [claimingviapiab, setClaimingViaPIAB] = useState("");
  const [nameaddresssolicitor, setNameAddressSolicitor] = useState("");

  const handleSave1 = (val) => {
    setFirstName(val);
  };
  const handleSave2 = (val) => {
    console.log("Edited Value -> ", val);
    setLastName(val);
  };
  const handleSave3 = (val) => {
    console.log("Edited Value -> ", val);
    setAddress(val);
  };
  const handleSave4 = (val) => {
    console.log("Edited Value -> ", val);
    setPhonenumber(val);
  };
  const handleSave5 = (val) => {
    console.log("Edited Value -> ", val);
    setEmail(val);
  };
  const handleSaveGPFirstName = (val) => {
    console.log("Edited Value -> ", val);
    setGPFirstName(val);
  };
  const handleSaveGPLastName = (val) => {
    console.log("Edited Value -> ", val);
    setGPLastName(val);
  };
  const handleSave7 = (val) => {
    console.log("Edited Value -> ", val);
    setGPConsent(val);
  };
  const handleSave8 = (val) => {
    console.log("Edited Value -> ", val);
    setGPPractice(val);
  };
  const handleSave9 = (val) => {
    console.log("Edited Value -> ", val);
    setNextOfKin(val);
  };
  const handleSave10 = (val) => {
    console.log("Edited Value -> ", val);
    setNextOFKinNumber(val);
  };
  const handleSave11 = (val) => {
    console.log("Edited Value -> ", val);
    setElectPrivate(val);
  };
  const handleSave12 = (val) => {
    console.log("Edited Value -> ", val);
    setSimptomsFirstNoticed(val);
  };
  const handleSave13 = (val) => {
    console.log("Edited Value -> ", val);
    setDrFirstConsulted(val);
  };
  const handleSave15 = (val) => {
    console.log("Edited Value -> ", val);
    setDateClaimedBefore(val);
  };
  const handleSave16 = (val) => {
    console.log("Edited Value -> ", val);
    setDrNameFirstReferred(val);
  };
  const handleSave17 = (val) => {
    console.log("Edited Value -> ", val);
    setDrNameFirstReferredDate(val);
  };
  const handleSave18 = (val) => {
    console.log("Edited Value -> ", val);
    setDrNameFirstReferredAddress(val);
  };
  const handleSave19 = (val) => {
    console.log("Edited Value -> ", val);
    setResultOfAccident(val);
  };
  const handleSave20 = (val) => {
    console.log("Edited Value -> ", val);
    setDateOfAccident(val);
  };
  const handleSave23 = (val) => {
    console.log("Edited Value -> ", val);
    setFaultOfOtherParty(val);
  };
  const handleSave24 = (val) => {
    console.log("Edited Value -> ", val);
    setContactInformationOtherParty(val);
  };
  const handleSave25 = (val) => {
    console.log("Edited Value -> ", val);
    setResponsiblePartyInsuranceCompany(val);
  };
  const handleSave26 = (val) => {
    console.log("Edited Value -> ", val);
    setClaimingViaSolicitor(val);
  };
  const handleSave27 = (val) => {
    console.log("Edited Value -> ", val);
    setClaimingViaPIAB(val);
  };
  const handleSave28 = (val) => {
    console.log("Edited Value -> ", val);
    setNameAddressSolicitor(val);
  };
  const handleSave29 = (val) => {
    console.log("Edited Value -> ", val);
    setMemberShipNo(val);
  };
  const handleSave30 = (val) => {
    console.log("Edited Value -> ", val);
    setDOB(val);
  };
  const handleSave32 = (val) => {
    console.log("Edited Value -> ", val);
    setILH_Policy_Number(val);
  };
  const handleSave33 = (val) => {
    console.log("Edited Value -> ", val);
    setDateInsurerInvoiced(val);
  };

  const validationFailed = (textValue) => {
    alert(`The text <${textValue}> is not valid.
    A valid policy number is 10 digits`);
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // const onUpdateRecord = (e) => {
  //   e.preventDefault();
  //   updatePatient();
  // };

  console.log("isEditPatient ", isEditPatient);

  const updateInvoice = async () => {
    if (!patient) {
      return;
    }

    const [maybeFractureClinic, insurancePlanName] =
      insurerIdAndPlanName.split("|");
    const isFractureClinic = "5" === maybeFractureClinic;
    const plan = insuPolicies.find(
      (v) =>
        v.Policy_Plan?.toLowerCase() === insurancePlanName &&
        v.IsPolicyActive === true &&
        (isFractureClinic ? v.InsurerId === 5 : v.InsurerId !== 5)
    );
    console.log("plan " + plan);
    console.log("insurancePlanName ", insurancePlanName);
    console.log("insurancePlanName ", insurancePlanName);
    console.log("isResubmitPatient ", isResubmitPatient);
    console.log("maxCover ", maxCover);

    if (isResubmitInsurance || isResubmitPatient) {
      await mutate({
        variables: {
          patientId: parseInt(patient.id),
          Insurance_Payment_Status: isResubmitPatient
            ? "Awaiting Patient Invc"
            : "Awaiting Submit Insurance Invc", // the invoice needs to be submitted on the cost and invoice page - problem with calling Awiaitng Decision too early, the invoice was getting called before being finalised.
          Insurance_Payment_Other_Information: isResubmitPatient
            ? "Appended Patient Invc"
            : "Appended Insurance Invc", // + originalInsuranceAmount,
          Submit_Status: isResubmitPatient ? 1 : 0,
          Invoice_Number: parseInt(patient.id),
          Maximum_Cover: maxCover || 0,
          ts_Date_Insurance_Invoiced: convertUnixDate4(dateInsurerInvoiced),
          // Appended_Invoice_Number: patient.id + "A",
          // Appended_Invoice_Number: isResubmitPatient ? "P" : isResubmitInsurance ? "A" : "", // changed so we can concat with invoice number for API --Needed to add "" as the DB cannot compare != 'P' or 'A' if it is null and hence we get invoices that are not getting invoiced
          Appended_Invoice_Number: isResubmitPatient ? "P" : "A",
          Health_Membership_Number: memberNo,
          numberOfPreviousVisits: Number(numberOfPreviousVisits) || 0,
          ILH_Policy_Number: ILH_Policy_Number || "0000000",
          Policy_IsActive_YesNo: policyActive,
          Insurance_Plan_Name: insurerIdAndPlanName.toLowerCase(),
          Manual_or_Automatic_Insurance_Details_Fetched:
            (patientData?.patientbyid?.Health_Insurance_Company === 2 &&
              localDBStatusMessage !== "Live") ||
            (patientData?.patientbyid?.Health_Insurance_Company === 1 &&
              localDBStatusMessage !== "Member found")
              ? "Manual"
              : "Automatic",
          // InsurancePlanId: parseInt(plan!.id),
          isExcessModelYesNo: UseExcessCalculation,
        },
      });
    }

    await mutate({
      variables: {
        patientId: parseInt(patient.id),
        Maximum_Cover: maxCover || 0,
        Policy_IsActive_YesNo: policyActive,
        Insurance_Plan_Name: insurerIdAndPlanName.toLowerCase(),
        // InsurancePlanId: parseInt(plan!.id),
        Invoice_Number: parseInt(patient.id),
        Health_Membership_Number: memberNo,
        numberOfPreviousVisits: Number(numberOfPreviousVisits) || 0,
        ILH_Policy_Number: ILH_Policy_Number || "0000000",
        Manual_or_Automatic_Insurance_Details_Fetched:
          (patientData?.patientbyid?.Health_Insurance_Company === 2 &&
            localDBStatusMessage !== "Live") ||
          (patientData?.patientbyid?.Health_Insurance_Company === 1 &&
            localDBStatusMessage !== "Member found")
            ? "Manual"
            : "Automatic",
        isExcessModelYesNo: UseExcessCalculation,
        // ts_Date_Insurance_Invoiced: convertUnixDate4(dateInsurerInvoiced),
      },
    })
      .then((res) => {
        if (res.data) {
          if (isEditPatient) {
            history.goBack();
          } else if (isResubmitInsurance) {
            history.replace(`/cai-resubmit-invoice/${pid}`);
          } else if (isResubmitPatient) {
            history.replace(`/cai-resubmit-patient/${pid}`);
          } else {
            history.replace(`/cost-and-invoice/${pid}`);
            // history.replace(`/fetch-patients`);
          }
        } else if (res.data) {
          history.replace("/fetch-patients");
          // history.replace("/admit-patients");
        }
      })
      .catch((error) => {
        //console.log(error)
      });
    console.log("plan " + plan);
    console.log("insurancePlanName ", insurancePlanName);
  };

  //Section below added for ILH Migration
  console.log("phonenumber ", phonenumber);
  const [memberNoError, setMemberNoError] = useState("");
  // const [ILH_Policy_Number_Error, setILH_Policy_Number_Error] = useState("");
  // const [
  //   ILH_NumberOfPreviousVisits_Error,
  //   setILH_NumberOfPreviousVisits_Error,
  // ] = useState("0");
  //TODO: look into why it is not working with ""

  // eslint-disable-next-line
  const updatePatient = async (refresh?: boolean) => {
    if (!patient) {
      return;
    }
    //regex validation on user input on membership number.
    //Laya
    console.log("memberNo", memberNo);
    if (
      //Laya & Laya FC
      (patientData?.patientbyid?.Health_Insurance_Company === 1 ||
        patientData?.patientbyid?.Health_Insurance_Company === 5) &&
      !membershipNumberRegexLaya.test(memberNo)
    ) {
      setMemberNoError("invalid membership number");
      return;
      //ILH
    } else if (
      patientData?.patientbyid?.Health_Insurance_Company === 2 &&
      (!membershipNumberRegexILH.test(memberNo) ||
        membershipNumberRegex7Zeros.test(memberNo))
    ) {
      setMemberNoError("invalid membership number");
      return;
      //SF & AC
    } else if (memberNoError) {
      setMemberNoError("");
    }

    // //regex validation on user input for policy number.
    // console.log("ILH_Policy_Number", ILH_Policy_Number);
    // if (
    //   patientData?.patientbyid?.Health_Insurance_Company === 2 &&
    //   !policyNumberRegex.test(ILH_Policy_Number.toUpperCase())
    // ) {
    //   setILH_Policy_Number_Error("invalid policy number - e.g. 00VIVW1456");
    //   return;
    // } else if (ILH_Policy_Number_Error) {
    //   setILH_Policy_Number_Error("invalid policy number");
    // }

    // if (
    //     !numberOfPreviousVisitsRegex.test(numberOfPreviousVisits) &&
    //     (patientData?.patientbyid?.Health_Insurance_Company === 1
    //     || patientData?.patientbyid?.Health_Insurance_Company === 2
    //     || patientData?.patientbyid?.Health_Insurance_Company === 5)
    // ) {
    //     setILH_NumberOfPreviousVisits_Error("Invalid number of previous visits");
    //     return;
    // } else if (ILH_NumberOfPreviousVisits_Error) {
    //     setILH_NumberOfPreviousVisits_Error("");
    // }
    //Section above added for ILH Migration

    const update: UpdatePatientMutationVariables["update"] = {
      Health_Membership_Number: memberNo || "0000000000",
      ILH_Policy_Number: ILH_Policy_Number ? ILH_Policy_Number : "",
      MM_Invoice_Number: patient.id,
      // MRN: mmpid || undefined,
      DOB: dob || convertUnixDate4(patient.DOB),
      Patient_Forenames: firstName || patient.Patient_Forenames,
      Patient_Surname: lastName || patient.Patient_Surname,
      Address: adderss || patient.Address || "",
      Phone_Number: phonenumber || patient.Phone_Number,
      email: email || patient.email,
      GP_FirstName: gpfirstname || patient.GP_FirstName,
      GP_LastName: gplastname || patient.GP_LastName,
      GPConsentYesNo: gpconsent || patient.GPConsentYesNo,
      GP_PracticeName:
        gppractice || remove_linebreaks2(patient.GP_PracticeName),
      NextOfKin: nextofkin || patient.NextOfKin,
      NextOfKinPhoneNumber: nextofkinnumber || patient.NextOfKinPhoneNumber,
      // ts_Date_Patient_Registered:
      //   ts_date_Patient_Registered || patient.ts_Date_Patient_Registered,
      Did_Patient_Elect_To_Be_Private_YesNo:
        electprivate || patient.Did_Patient_Elect_To_Be_Private_YesNo,
      ts_Symptoms_First_Noticed:
        symptomsfirstnoticed ||
        convertUnixDate3(patient.ts_Symptoms_First_Noticed),
      ts_Doctor_First_Consulted_MedHistory:
        drfirstconsulted ||
        convertUnixDate3(patient.ts_Doctor_First_Consulted_MedHistory),
      ts_Date_When_Claimed_For_This_Illness_Before:
        dateclaimedbefore ||
        convertUnixDate3(patient.ts_Date_When_Claimed_For_This_Illness_Before),
      Name_Of_Doctor_Fisrt_Attended_Referral:
        drnamefirstreferred || patient.Name_Of_Doctor_Fisrt_Attended_Referral,
      ts_Date_Of_Doctor_First_Attended_Referral:
        drnamefirstreferreddate ||
        convertUnixDate3(patient.ts_Date_Of_Doctor_First_Attended_Referral),
      Address_Of_Doctor_First_Attended_Referral:
        drnamefirstreferredaddrtess ||
        remove_linebreaks2(patient.Address_Of_Doctor_First_Attended_Referral),
      Admission_IsResult_Of_Accident_YesNo:
        resultofaccident || patient.Admission_IsResult_Of_Accident_YesNo,
      ts_Date_of_Accident:
        dateofaccident || convertUnixDate3(patient.ts_Date_of_Accident),
      Was_Accident_Injury_Fault_of_Another_Party_YesNo:
        faultofotherparty ||
        patient.Was_Accident_Injury_Fault_of_Another_Party_YesNo,
      Contact_Information_of_Responsible_Party:
        contactoinformationotherparty ||
        remove_linebreaks2(patient.Contact_Information_of_Responsible_Party),
      Responsible_Party_Insurance_Company_Information:
        responsiblepartyinsurancecompany ||
        remove_linebreaks2(
          patient.Responsible_Party_Insurance_Company_Information
        ),
      Are_You_Claiming_Expenses_Via_Solicitor_YesNo:
        claimingviasolicitor ||
        patient.Are_You_Claiming_Expenses_Via_Solicitor_YesNo,
      Are_You_Claiming_Expenses_Via_PIAB_YesNo:
        claimingviapiab || patient.Are_You_Claiming_Expenses_Via_PIAB_YesNo,
      Name_Address_of_Solicitor_If_Applicable:
        nameaddresssolicitor ||
        remove_linebreaks2(patient.Name_Address_of_Solicitor_If_Applicable),
    };

    if (!isEditPatient) {
      update.ts_Admit_Date = formatDate(new Date());
    }

    await updatePatientMutation({
      variables: {
        id: parseInt(patient.id),
        update,
      },
    })
      .then((res) => {
        if (refresh) {
          window.location.reload();
        } else if (res.data) updateInvoice();
      })
      .catch((error) => {
        //console.log(error)
      });
  };

  const [maybeFractureClinic] = insurerIdAndPlanName.split("|");
  const isFractureClinic = "5" === maybeFractureClinic;
  const plan = insuPolicies.find(
    (v) =>
      v.Policy_Plan?.toLowerCase() === insurerIdAndPlanName &&
      v.IsPolicyActive === true &&
      (isFractureClinic ? v.InsurerId === 5 : v.InsurerId !== 5)
  );

  if (!patient) {
    return null;
  }
  console.log("localDBStatusMessage ", localDBStatusMessage);
  return (
    <div>
      <div className="container my-5">
        <div className={classes.sectionHead}>
          <h6 className="p-2">Patient Check-In</h6>
        </div>
        <div
          className={`${classes.fieldContainer} ${
            matches ? "d-flex flex-column" : "d-flex"
          }`}
        >
          <table className="table">
            <thead>
              <tr>
                <th scope="col" colSpan={1} style={{ color: "blue" }}>
                  Person that filled out registration form{": "}
                  {patient.Patient_Forenames} {patient.Patient_Surname}
                </th>
              </tr>
              <tr></tr>
              <tr>
                <th scope="col" colSpan={2}>
                  {"Insured Patient Name (Person being treated): "}
                  {firstName || patient.Patient_Forenames}{" "}
                  {lastName || patient.Patient_Surname}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  {(patientData?.patientbyid?.Health_Insurance_Company === 3 ||
                    patientData?.patientbyid?.Health_Insurance_Company ===
                      4) && (
                    <div className="mt-3">
                      {" "}
                      Zero is default for Self Pay and Account{" "}
                      <CodeInput
                        value={"0000000000"}
                        fields={7}
                        onChange={(e) => setMemberShipNo(e)}
                        disabled={true}
                      />{" "}
                    </div>
                  )}

                  <div className="mt-3">
                    Member number:{" "}
                    {localDBMemberNumber ? localDBMemberNumber : memberNo}
                  </div>
                  {memberNoError ? (
                    <div style={{ color: "red" }}>{memberNoError}</div>
                  ) : null}
                  {/* This is where the input is getting displayed if a value exists */}
                  <div style={{ color: "blue" }}>
                    Previous value:{" "}
                    {isEditPatient || isResubmitInsurance || isResubmitPatient
                      ? patient.Health_Membership_Number
                      : memberNo}{" "}
                  </div>
                </td>

                <td>
                  {patientData?.patientbyid?.Health_Insurance_Company ===
                    2 /*&& localDBStatusMessage === "Live"*/ && (
                    <div className="mt-3">
                      {/* {" "}
                                        Letters and numbers - VIV required{" "}
                                        {
                                            <CodeInput
                                                value={ILH_Policy_Number}
                                                fields={10}
                                                onChange={(e) => setILH_Policy_Number(e)}
                                            />
                                        }{" "} */}
                      <div>
                        Policy number:{" "}
                        {localDBPolicyNumber
                          ? localDBPolicyNumber
                          : ILH_Policy_Number}
                      </div>

                      {/* localDBMemberNumber === "" */}
                      {/* {ILH_Policy_Number_Error ? (
                        <div style={{ color: "red" }}>
                          {ILH_Policy_Number_Error}
                        </div>
                      ) : null} */}
                      {/* This is where the input is getting displayed if a value exists */}
                      <div style={{ color: "blue" }}>
                        Previous value:{" "}
                        {isEditPatient || isResubmitInsurance
                          ? patient.ILH_Policy_Number
                          : ILH_Policy_Number}{" "}
                      </div>
                    </div>
                  )}

                  {/* a copy of the above section was removed where we could bring back the option to manually input the policy number 
                checkout commit to migration -> https://github.com/medosync1/afexpresscare_client/commit/5bd2623eaf2cf0947cad275da7281cea8ca04bd6*/}

                  <div className="mt-3">
                    {/* <div>Insurance plan: {(patientData?.patientbyid?.Health_Insurance_Company === 2 && localDBStatusMessage !== "Live" ) ? "" : insurerIdAndPlanName}</div> */}
                    <div>Insurance plan: {insurerIdAndPlanName}</div>
                    {patientData?.patientbyid?.Health_Insurance_Company === 4 ||
                    patientData?.patientbyid?.Health_Insurance_Company === 3 ? (
                      // || (patientData?.patientbyid?.Health_Insurance_Company === 2 && localDBStatusMessage !== "Live" )
                      // || (patientData?.patientbyid?.Health_Insurance_Company === 1 && localDBStatusMessage !== "Member found")
                      <>
                        <select
                          className="form-control"
                          id="plan-name"
                          required={true}
                          value={insurerIdAndPlanName}
                          onChange={(e) => {
                            const [
                              insurerIdString,
                              policyPlan,
                              numberOfFreeVisits,
                            ] = e.target.value.split("|");
                            setInsurerIdAndPlanName(e.target.value);
                            const insurerId = parseInt(insurerIdString);
                            const fv = parseInt(numberOfFreeVisits);
                            console.log("insurerIdString ", insurerIdString);
                            console.log(
                              "numberOfFreeVisits ",
                              numberOfFreeVisits,
                              "fv ",
                              fv
                            );
                            const n = insuPolicies.find(
                              (v) =>
                                v.Policy_Plan?.toLowerCase() === policyPlan &&
                                insurerId === v.InsurerId &&
                                v.IsPolicyActive === true &&
                                fv === v.NumberOfFreeVisits
                            )?.MaxCover;
                            if (n || n === 0) {
                              setMaxCover(n);
                            }
                          }}
                        >
                          <option value={""}>Select Policy</option>
                          {insuPolicies.map((v, i) => {
                            // if (
                            //   myInsuranceCompany &&
                            //   v.Insurance_Company !== myInsuranceCompany
                            // ) {
                            //   return null;
                            // }
                            if (
                              patientData?.patientbyid
                                ?.Health_Insurance_Company &&
                              patientData.patientbyid
                                .Health_Insurance_Company !== v.InsurerId
                            ) {
                              return null;
                            }
                            if (!v.IsPolicyActive) {
                              return null;
                            }
                            return (
                              <option
                                key={i}
                                value={`${v.Policy_Plan?.toLowerCase()}`}
                              >
                                {v.Policy_Plan?.toLowerCase()}
                                {/* |{v.Insurance_Company}|{v.NumberOfFreeVisits} Free Visit */}
                                {/* {v.Policy_Plan} */}
                              </option>
                            );
                          })}
                        </select>
                      </>
                    ) : null}
                    {console.log(
                      "numberOfPreviousVisits ",
                      numberOfPreviousVisits
                    )}
                    {/* {console.log("Plan " + insurerIdAndPlanName.split("|"))}
                    {console.log("IsInvoice" + isEditPatient)} */}
                    <div style={{ color: "blue" }}>
                      Previous value:{" "}
                      {isEditPatient || isResubmitInsurance || isResubmitPatient
                        ? invoiceBillData?.InvoiceBill?.[0].Policy_Plan?.toLowerCase()
                        : insurerIdAndPlanName}{" "}
                    </div>
                  </div>
                </td>
                <td>
                  {console.log(
                    "insurerIdAndPlanName ",
                    insurerIdAndPlanName.split("|")[2]
                  )}
                  {(plan?.NumberOfFreeVisits || 0) > 0 && (
                    <div className="mt-3">
                      {" "}
                      Check for previous visits on insurer portal{" "}
                      <CodeInput
                        value={numberOfPreviousVisits}
                        fields={1}
                        onChange={(e) => setNumberOfPreviousVisits(e)}
                        required={true}
                      />
                      <div style={{ color: "blue" }}>
                        {isEditPatient ||
                        isResubmitInsurance ||
                        isResubmitPatient
                          ? "Previous value: " +
                            invoiceBillData?.InvoiceBill?.[0]
                              .numberOfPreviousVisits
                          : ""}
                      </div>
                      {/* {ILH_NumberOfPreviousVisits_Error ? (
                        <div style={{ color: "red" }}>
                          {ILH_NumberOfPreviousVisits_Error}
                        </div>
                      ) : null} */}
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={`${classes.fieldContainer} ${
            matches ? "d-flex flex-column" : "d-flex"
          }`}
        >
          <table className="table">
            {/* <thead>
              <tr>
                <th>Previous Record Lookup date: {ts_InsurerAPIQueried}</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>Member Number: {localDBMemberNumber}</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>
                  Patient Name: {localDBFirstName} {localDBSurname}
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>Policy Name: {insurerIdAndPlanName}</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>---------------------------------</th>
              </tr>
            </thead> */}
            {isLayaPatient ? (
              <>
                <thead>
                  <tr>
                    <th>
                      Laya API Policy Number:{" "}
                      {localDBPolicyNumber === ""
                        ? "Member not found - Please recheck DOB & member number or manually enter information"
                        : isLayaPatient
                        ? localDBPolicyNumber
                        : ""}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>
                      Laya API Plan Name:{" "}
                      {layaInsurancePlanName === ""
                        ? "Member not found - Please recheck DOB & member number or manually enter information"
                        : layaInsurancePlanName}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>
                      Laya Plan Type:{" "}
                      {layaInsurancePlanType === ""
                        ? "Member not found - Please recheck DOB & member number or manually enter information"
                        : layaInsurancePlanType}
                    </th>
                  </tr>
                </thead>
              </>
            ) : null}
            {isILHPatient && (
              <>
                <thead>
                  <tr>
                    <th>
                      Member Number:{" "}
                      {localDBMemberNumber === ""
                        ? "Member not found - Please recheck DOB & member numbe or manually enter information"
                        : isILHPatient
                        ? localDBMemberNumber
                        : ""}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    {/* <th> */}
                    ILH Policy Number:{" "}
                    {localDBPolicyNumber === ""
                      ? "Member not found - Please recheck DOB & member number or manually enter information"
                      : localDBPolicyNumber}
                    {/* </th> */}
                  </tr>
                </thead>
              </>
            )}

            <thead>
              <tr>
                <th
                  style={{
                    color:
                      (isILHPatient && localDBStatusMessage !== "Live") ||
                      (isLayaPatient && localDBStatusMessage !== "Member found")
                        ? "red"
                        : "",
                  }}
                >
                  API Lookup Status:{" "}
                  {localDBStatusMessage === ""
                    ? "Member not found - Please recheck DOB & member number or manually enter information"
                    : localDBStatusMessage}
                </th>
              </tr>
            </thead>

            {/* <thead>
                    <tr>
                        <th scope="col">
                            Is {patient.Patient_Forenames}'s policy active?
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                  <span>
                    Yes
                    <Radio
                        color="primary"
                        name="active-policy"
                        required={true}
                        value="yes"
                        onChange={(e) => setPolicyActive(e.target.value)}
                        checked={policyActive === "yes"}
                    />
                    No
                    <Radio
                        color="primary"
                        name="active-policy"
                        required={true}
                        value="no"
                        onChange={(e) => setPolicyActive(e.target.value)}
                        checked={policyActive === "no"}
                    />
                  </span>
                        </td>
                    </tr>
                    </tbody>*/}
          </table>
        </div>

        {/* {policyActive === "no" && (
                    <div className="alert alert-danger">
                        {" "}
                        Please inform patient that they are not covered!{" "}
                    </div>
                )}
                <div className="d-flex justify-content-end">
                    {(patientData?.patientbyid?.Health_Insurance_Company === 1 ||
                        patientData?.patientbyid?.Health_Insurance_Company === 5) && (
                        <Button
                            type={"submit"}
                            variant="contained"
                            color="primary"
                            className="my-2"
                            onClick={() => {
                                // useOnClickVerifyLayaMember(pid);
                            }}
                        >
                            Verify Laya Membership
                        </Button>)}
                </div> */}
        {/* <div className="d-flex justify-content-end">
                    {(patientData?.patientbyid?.Health_Insurance_Company === 2)
                        && (
                            <Button
                                type={"submit"}
                                variant="contained"
                                color="primary"
                                className="my-2">
                                Verify ILH Membership
                            </Button>
                        )}
                </div> */}

        <div className="d-flex justify-content-end">
          <Button
            type={"submit"}
            variant="contained"
            color="secondary"
            className="my-3"
            onClick={() =>
              // history.push(`/edit-invoice/${isEditPatient ? pid : ""}`)
              history.push(
                isEditPatient
                  ? `/edit-invoice/${isEditPatient ? pid : ""}`
                  : `/cost-and-invoice/${pid}`
              )
            }
          >
            Cancel
          </Button>
          {(isLayaPatient || isILHPatient) && (
            <Button
              variant="contained"
              color="default"
              className="my-3 ml-3"
              onClick={() => {
                updatePatient(true);
              }}
            >
              Get patient policy
            </Button>
          )}
          <div className="button-spacing"></div>
          {isResubmitInsurance && (
            <Button
              variant="contained"
              color="primary"
              className="my-3"
              onClick={() => {
                updatePatient();
              }}
              // onClick={() => history.push(`/resubmit/${pid}`)}
            >
              Resubmit Insurance Invoice
            </Button>
          )}
          <div className="button-spacing"></div>
          {isResubmitPatient && (
            <Button
              type={"submit"}
              variant="contained"
              color="primary"
              className="my-3"
              onClick={() => {
                updatePatient();
              }}
              // onClick={() => history.push(`/resubmit/${pid}`)}
            >
              Resubmit Patient Invoice
            </Button>
          )}
          {!(isResubmitInsurance || isResubmitPatient) && (
            <Button
              variant="contained"
              color="primary"
              className="my-3"
              onClick={() => {
                updatePatient();
              }}
            >
              {isEditPatient ? "Save" : "Next"}
            </Button>
          )}
        </div>

        <div className="container my-5 ">
          <div className="dropdown d-flex justify-content-center p-2">
            {(patientData?.patientbyid?.Health_Insurance_Company === 1 ||
              patientData?.patientbyid?.Health_Insurance_Company === 2 ||
              patientData?.patientbyid?.Health_Insurance_Company === 5) && (
              <button
                className="dropdown-toggle btn btn-success btn-sm p-2"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Recapture Signature
              </button>
            )}
            {(patientData?.patientbyid?.Health_Insurance_Company === 1 ||
              patientData?.patientbyid?.Health_Insurance_Company === 5) && (
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => {
                    history.push({
                      pathname: `/recapture/${pid}`,
                    });
                  }}
                >
                  Recapture Signature via iPad
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => {
                    history.push({
                      pathname: `/sig/recapture/${pid}`,
                    });
                  }}
                >
                  Recapture Signature via Topaz
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => {
                    history.push({
                      ///send/laya/152/0857582281
                      pathname: `/send/laya/${pid}/`,
                    });
                  }}
                >
                  Recapture Signature via SMS
                </span>
              </div>
            )}
            {patientData?.patientbyid?.Health_Insurance_Company === 2 && (
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => {
                    history.push({
                      pathname: `/recaptureIL/${pid}`,
                    });
                  }}
                >
                  Recapture Signature via iPad
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => {
                    history.push({
                      pathname: `/sig/recaptureIL/${pid}`,
                    });
                  }}
                >
                  Recapture Signature via Topaz
                </span>
                <span
                  className="dropdown-item cursor-pointer "
                  onClick={() => {
                    history.push({
                      ///send/laya/152/0857582281
                      pathname: `/send/il/${pid}/`,
                    });
                  }}
                >
                  Recapture Signature via SMS
                </span>
              </div>
            )}
          </div>

          <div className={classes.sectionHead}>
            <h6 className="p-2">Information Submitted by Patient</h6>
          </div>
          <div
            className={`${classes.fieldContainer} ${
              matches
                ? "d-flex flex-column bd-highlight p-2"
                : "d-flex flex-column bd-highlight p-2"
            }`}
          >
            <p>
              {" "}
              Date Patient Registered{" "}
              <b>{convertUnixDate2(patient.ts_Date_Patient_Registered)}</b>
            </p>

            <p>
              {" "}
              {!patient.Patient_Signature ? (
                <SignatureMissing />
              ) : (
                <img src={patient.Patient_Signature || ""} alt="sign-img" />
              )}
            </p>
            {/* <div className="d-flex justify-content-end">
              <Button
                // type={"submit"}
                onClick={() =>
                  history.push(
                    `/recapture/${patientData?.patientbyid?.Health_Insurance_Company}/${pid}`
                  )
                }
                variant="contained"
                color="primary"
                className="my-3"
              >
                Recapture Signature via SMS
              </Button>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {" "}
            </div>
            <table className="table">
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Membership Number:</td>
                  <td>
                    {!isLayaPatient ? (
                      <EdiText
                        hint="Membership number must have Min. 6 digits for ILH."
                        validation={(val) => val.length > 5}
                        onValidationFail={validationFailed}
                        inputProps={{
                          placeholder: "Enter Membership Number",
                        }}
                        showButtonsOnHover={false}
                        buttonsAlign="before"
                        type="text"
                        value={patient.Health_Membership_Number || ""}
                        onSave={handleSave29}
                      />
                    ) : (
                      <EdiText
                        hint="Membership number must have 10 digits for Laya."
                        validation={(val) => val.length > 9}
                        onValidationFail={validationFailed}
                        inputProps={{
                          placeholder: "Enter Membership Number",
                        }}
                        showButtonsOnHover={false}
                        buttonsAlign="before"
                        type="text"
                        value={patient.Health_Membership_Number || ""}
                        onSave={handleSave29}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Patient DOB (dd-mm-yyyy):</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      inputProps={{
                        min: "1900-01-01",
                        max: new Date().toDateString(),
                      }}
                      type="date"
                      hint="The calendar/input box will automatically fix the date format to yyyy-mm-dd. e.g. select 29/05/1984 and it will be fixed to the correct format of 1984-05-29"
                      value={convertUnixDate(patient.DOB)}
                      onSave={handleSave30}
                    />
                  </td>
                </tr>
                {isILHPatient ? (
                  <tr>
                    <td>ILH Policy Number:</td>
                    <td>
                      <EdiText
                        hint="ILH number must contain VIV."
                        validation={(val) => val.length > 5}
                        onValidationFail={validationFailed}
                        inputProps={{
                          placeholder: "must contain VIV in the policy number",
                        }}
                        showButtonsOnHover={false}
                        buttonsAlign="before"
                        type="text"
                        value={patient.ILH_Policy_Number || ""}
                        onSave={handleSave32}
                      />
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {/* <tr>
                  <td>Previous Visits (ILH):</td>
                  <td>
                    <EdiText
                      hint="ILH number must contain VIV."
                      validation={(val) => val.length > 1}
                      onValidationFail={validationFailed}
                      inputProps={{
                        placeholder: "must contain VIV in the policy number",
                      }}
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={insurancePlanName || ""}
                      onSave={handleSave32}
                    />
                  </td>
                </tr> */}
                {/* <tr>
                  <td>Date Patient Registered (dd-mm-yyyy):</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      inputProps={{
                        min: "1900-01-01",
                        max: new Date().toDateString(),
                      }}
                      type="date"
                      hint="The defalut placeholder date is 1900-01-01, You don't need to change it if this field is not relevant to the claim. The calendar/input box will automatically fix the date format to yyyy-mm-dd. e.g. select 29/05/1984, and it will be fixed to the correct format of 1984-05-29"
                      value={
                        patient.ts_Date_Patient_Registered
                          ? convertUnixDate(patient.ts_Date_Patient_Registered)
                          : "1900-01-01"
                      }
                      onSave={handleSave31}
                    />
                  </td>
                </tr> */}
                {isResubmitInsurance ? (
                  <tr>
                    <td>Insurer Invoice Date:</td>
                    <td>
                      <EdiText
                        showButtonsOnHover={false}
                        buttonsAlign="before"
                        inputProps={{
                          min: "",
                          max: new Date().toDateString(),
                          placeholder: "Must be dd-mm-yyy if modified.",
                        }}
                        type="date"
                        hint="Change this date to backdate the insurer invoice date."
                        value={
                          dateInsurerInvoiced
                            ? convertUnixDate(
                                invoiceBillData?.InvoiceBill[0]
                                  .ts_Date_Insurance_Invoiced
                              )
                            : ""
                        }
                        onSave={handleSave33}
                      />
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                <tr>
                  <td>Patient_Forenames:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={patient.Patient_Forenames || ""}
                      onSave={handleSave1}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Patient_Surname:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={patient.Patient_Surname || ""}
                      onSave={handleSave2}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={patient.Address ? patient.Address : ""}
                      onSave={handleSave3}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Phone_Number:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={patient.Phone_Number ? patient.Phone_Number : ""}
                      onSave={handleSave4}
                    />
                  </td>
                </tr>
                <tr>
                  <td>GPConsentYesNo:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={patient.GPConsentYesNo || ""}
                      onSave={handleSave7}
                    />
                  </td>
                </tr>
                <tr>
                  <td>GP_FirstName:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={patient.GP_FirstName ? patient.GP_FirstName : ""}
                      onSave={handleSaveGPFirstName}
                    />
                  </td>
                </tr>
                <tr>
                  <td>GP_LastName:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={patient.GP_LastName ? patient.GP_LastName : ""}
                      onSave={handleSaveGPLastName}
                    />
                  </td>
                </tr>
                <tr>
                  <td>GP_PracticeName:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.GP_PracticeName ? patient.GP_PracticeName : ""
                      }
                      onSave={handleSave8}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Emergency Contact Name:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={patient.NextOfKin ? patient.NextOfKin : ""}
                      onSave={handleSave9}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Emergency Contact Number:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.NextOfKinPhoneNumber
                          ? patient.NextOfKinPhoneNumber
                          : ""
                      }
                      onSave={handleSave10}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>
                    <EdiText
                      hint="Email must be valid a@a.com"
                      inputProps={{
                        placeholder: "Please use a valid email address.",
                      }}
                      validation={(val) => validateEmail(val)}
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="email"
                      value={patient.email ? patient.email : ""}
                      onSave={handleSave5}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Patient Private:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Did_Patient_Elect_To_Be_Private_YesNo
                          ? patient.Did_Patient_Elect_To_Be_Private_YesNo
                          : ""
                      }
                      onSave={handleSave11}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Symptoms First Noticed (dd-mm-yyyy):</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      inputProps={{
                        min: "1900-01-01",
                        max: new Date().toDateString(),
                      }}
                      type="date"
                      hint="The defalut placeholder date is 1900-01-01, You don't need to change it if this field is not relevant to the claim. The calendar/input box will automatically fix the date format to yyyy-mm-dd. e.g. select 29/05/1984 and it will be fixed to the correct format of 1984-05-29"
                      value={
                        patient.ts_Symptoms_First_Noticed
                          ? convertUnixDate(patient.ts_Symptoms_First_Noticed)
                          : "1900-01-01"
                      }
                      onSave={handleSave12}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Doctor First Consulted MedHistory (dd-mm-yyyy):</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      inputProps={{
                        min: "1900-01-01",
                        max: new Date().toDateString(),
                      }}
                      type="date"
                      hint="The defalut placeholder date is 1900-01-01, You don't need to change it if this field is not relevant to the claim. The calendar/input box will automatically fix the date format to yyyy-mm-dd. e.g. select 29/05/1984 and it will be fixed to the correct format of 1984-05-29"
                      value={
                        patient.ts_Doctor_First_Consulted_MedHistory
                          ? convertUnixDate(
                              patient.ts_Doctor_First_Consulted_MedHistory
                            )
                          : "1900-01-01"
                      }
                      onSave={handleSave13}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td>Previously Claimed For Illness:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Previously_Claimed_For_Illness_YesNo
                          ? patient.Previously_Claimed_For_Illness_YesNo
                          : ""
                      }
                      onSave={handleSave14}
                    />
                  </td>
                </tr> */}
                <tr>
                  <td>
                    Date When Claimed For This Illness Before (dd-mm-yyyy):
                  </td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      inputProps={{
                        min: "1900-01-01",
                        max: new Date().toDateString(),
                      }}
                      type="date"
                      hint="The defalut placeholder date is 1900-01-01, You don't need to change it if this field is not relevant to the claim. The calendar/input box will automatically fix the date format to yyyy-mm-dd. e.g. select 29/05/1984 and it will be fixed to the correct format of 1984-05-29"
                      value={
                        patient.ts_Date_When_Claimed_For_This_Illness_Before
                          ? convertUnixDate(
                              patient.ts_Date_When_Claimed_For_This_Illness_Before
                            )
                          : "1900-01-01"
                      }
                      onSave={handleSave15}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Name Of Doctor First Attended Referral:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Name_Of_Doctor_Fisrt_Attended_Referral
                          ? patient.Name_Of_Doctor_Fisrt_Attended_Referral
                          : ""
                      }
                      onSave={handleSave16}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Date Of Doctor First Attended Referral (dd-mm-yyyy):</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      inputProps={{
                        min: "1900-01-01",
                        max: new Date().toDateString(),
                      }}
                      type="date"
                      hint="The defalut placeholder date is 1900-01-01, You don't need to change it if this field is not relevant to the claim. The calendar/input box will automatically fix the date format to yyyy-mm-dd. e.g. select 29/05/1984 and it will be fixed to the correct format of 1984-05-29"
                      value={
                        patient.ts_Date_Of_Doctor_First_Attended_Referral
                          ? convertUnixDate(
                              patient.ts_Date_Of_Doctor_First_Attended_Referral
                            )
                          : "1900-01-01"
                      }
                      onSave={handleSave17}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Address Of Doctor First Attended Referral:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Address_Of_Doctor_First_Attended_Referral
                          ? patient.Address_Of_Doctor_First_Attended_Referral
                          : ""
                      }
                      onSave={handleSave18}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Admission_IsResult_Of_Accident_YesNo:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Admission_IsResult_Of_Accident_YesNo
                          ? patient.Admission_IsResult_Of_Accident_YesNo
                          : ""
                      }
                      onSave={handleSave19}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Date of Accident (dd-mm-yyyy):</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      inputProps={{
                        min: "1900-01-01",
                        max: new Date().toDateString(),
                      }}
                      type="date"
                      hint="The defalut placeholder date is 1900-01-01, You don't need to change it if this field is not relevant to the claim. The calendar/input box will automatically fix the date format to yyyy-mm-dd. e.g. select 29/05/1984 and it will be fixed to the correct format of 1984-05-29"
                      value={
                        patient.ts_Date_of_Accident
                          ? convertUnixDate(patient.ts_Date_of_Accident)
                          : "1900-01-01"
                      }
                      onSave={handleSave20}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td>Where Did Accident Injury Occur:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Where_Did_Accident_Injury_Occur
                          ? patient.Where_Did_Accident_Injury_Occur
                          : ""
                      }
                      onSave={handleSave21}
                    />
                  </td>
                </tr>
                <tr>
                  <td>How Did Accident Injury Occur:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.How_Did_Accident_Injury_Occur
                          ? patient.How_Did_Accident_Injury_Occur
                          : ""
                      }
                      onSave={handleSave22}
                    />
                  </td>
                </tr> */}
                <tr>
                  <td>Was Accident Injury Fault of Another Party:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Was_Accident_Injury_Fault_of_Another_Party_YesNo
                          ? patient.Was_Accident_Injury_Fault_of_Another_Party_YesNo
                          : ""
                      }
                      onSave={handleSave23}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Contact Information of Responsible Party:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Contact_Information_of_Responsible_Party
                          ? patient.Contact_Information_of_Responsible_Party
                          : ""
                      }
                      onSave={handleSave24}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Responsible Party_Insurance_Company_Information:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Responsible_Party_Insurance_Company_Information
                          ? patient.Responsible_Party_Insurance_Company_Information
                          : ""
                      }
                      onSave={handleSave25}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Are You Claiming Expenses Via Solicitor:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Are_You_Claiming_Expenses_Via_Solicitor_YesNo
                          ? patient.Are_You_Claiming_Expenses_Via_Solicitor_YesNo
                          : ""
                      }
                      onSave={handleSave26}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Are You Claiming Expenses Via PIAB:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Are_You_Claiming_Expenses_Via_PIAB_YesNo
                          ? patient.Are_You_Claiming_Expenses_Via_PIAB_YesNo
                          : ""
                      }
                      onSave={handleSave27}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Name Address of Solicitor If Applicable:</td>
                  <td>
                    <EdiText
                      showButtonsOnHover={false}
                      buttonsAlign="before"
                      type="text"
                      value={
                        patient.Name_Address_of_Solicitor_If_Applicable
                          ? patient.Name_Address_of_Solicitor_If_Applicable
                          : ""
                      }
                      onSave={handleSave28}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <p>
              {" "}
              Membership Number:{" "}
              <b>
                {patient.Health_Membership_Number
                  ? patient.Health_Membership_Number
                  : null}
              </b>
            </p> */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {" "}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
